<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title">Nouveau Profil</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close" v-if="!disabled"></button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="" class="form-label">Nom</label>
                        <input type="email" class="form-control form-control-sm" v-model="quotaName" />
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Instance(s)</label>
                        <input type="number" min="0" class="form-control form-control-sm" v-model="quotaInstances" />
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Stockage(s)</label>
                        <input type="number" min="0" class="form-control form-control-sm" v-model="quotaStorage" />
                    </div>
                   <label for="" class="form-label">Crédit(s) horaire</label>
                    <div class="input-group mb-3">
                        <input type="text" name="" id="" class="form-control form-control-sm text-center" :value="quotaInstances+' Instance(s)'" disabled readonly>
                        <span class="input-group-text text-center"><i class="bi bi-x"></i></span>
                        <input type="number" min="0" class="form-control form-control-sm text-center" v-model="quotaHours" />
                        <input type="text" name="" id="" class="form-control form-control-sm text-center" :value="'Hrs = '+quotaHoursTotal+' Crédit(s)'" disabled readonly>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="close()" :disabled="disabled"><i class="bi bi-x me-2"></i>Annuler</button>
                    <button type="button" class="btn btn-sm" :disabled="disabled" @click.prevent="addQuota()">
                        <i class="bi bi-check me-2"></i>Créer
                    </button>
                </div>
                <input type="hidden" name="" v-model="defaultQuota">
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";

    let props = defineProps(["modalId","show","userInfo"]);
    let emits = defineEmits(["close","error","success"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    let disabled = ref(false);
    let quotaName = ref("");
    let quotaInstances = ref(0);
    let quotaStorage = ref(0);
    let quotaHours = ref(0);
    let quotaHoursTotal = computed( () => {
        let credits = quotaInstances.value * quotaHours.value
        return credits;
    });


    onMounted( () => {
        
    });

    function close(){
        emits("close");
    }

    function addQuota(){
        disabled.value = true;
        axios.post("/api/quotas", {
            name: quotaName.value,
            maxInstances: quotaInstances.value,
            maxStorage: quotaStorage.value,
            maxHours: quotaHours.value
        })
            .then( answer => {
                disabled.value = false;
                emits("success");
                close();
            })
            .catch( answer => {
                disabled.value = false;
                emits("error", (answer.response.data.message) ? answer.response.data.message : answer);
            });
    }

</script>