<template>  
    <div>
        <h4 class="mb-3">
            Profils des quotas
            <div class="btn-group float-end" role="group" aria-label="Basic example">
                <button class="btn" @click="showQuotaAdd = true"><i class="bi bi-plus me-1"></i> Ajouter</button>
                <button class="btn" v-if="selectedQuotas.length === 0" @click="selectedQuotas = quotas.filter( q => q.name !== 'Standard' ).map( q => q._id)"><i class="bi bi-check-square me-1"></i> Tous/Aucun</button>
                <button class="btn" v-if="selectedQuotas.length > 0" @click="selectedQuotas = []"><i class="bi bi-square me-1"></i> Tous/Aucun</button>
                <button class="btn text-danger" :disabled="selectedQuotas.length === 0" @click="showConfirmTrash = true"><i class="bi bi-trash me-1"></i> Supprimer</button>
            </div>
        </h4>

        <p style="text-align: justify;">
        Vous pouvez gérer les différents profils de quotas utilisés par vos utilisateurs.
        Cliquez sur un profil pour l'éditer ou sur 'ajouter' pour en créer un nouveau.
        </p>

        <div class="alert alert-light">
            <h6>Explications sur les limites</h6>
            <ul>
                <li>Instance(s): Le nombre maximum de machines qu'un utilisateur peux créer.</li>
                <li>Stockage: Le nombre maximum de Go cumulés par les machines d'un utilisateur.</li>
                <li>Crédits horaire : Le nombre maximum d'heure cumulés par les instances d'un utilisateur.</li>
            </ul>
            <p style="text-align: justify;" class="mb-0"><em>*Une instance consome du crédit horaire individuellement. Si un utilisateur fait tourner 4 instances pendant 10 mins, elles auront consomées un total de 40mins de crédits et non pas 10mins. Une instance peux fonctionner tant qu'il reste du crédit horaire.</em></p>
        </div>

        <!-- List of quotas -->
        <ul class="list-group" v-if="loaded">
            <li class="list-group-item list-group-item-action" v-for="quota in quotas" :key="quota._id">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <input role="button" class="form-check-input me-2" type="checkbox" v-if="quota.name !== 'Standard'" :value="quota._id" v-model="selectedQuotas" aria-label="...">
                                <input class="form-check-input me-2" type="checkbox" disabled v-else/>
                            </td>
                            <td>
                                <i class="bi bi-ticket-perforated me-2"></i>
                            </td>
                            <th class="w-100">
                                {{quota.name}}
                                <em class="text-muted">
                                    <small>
                                        <span v-if="quota.name === 'Standard'">{{ "(protected)" }}</span>
                                    </small>
                                </em>
                            </th>
                            <td class="text-end">
                                <!-- <i class="bi bi-arrows-angle-expand me-2"></i> -->
                            </td>
                        </tr>
                    </tbody>
                </table>

                <em><ul>
                    <li>Instance(s) : {{quota.maxInstances}}</li>
                    <li>Stockage : {{quota.maxStorage}}</li>
                    <li>Crédits horaire : {{quota.maxHours*quota.maxInstances}}</li>
                </ul></em>

            </li>
        </ul>
        <div v-else>
            <span class="spinner spinner-border spinner-border-sm me-2"></span> Chargement... {{loaded}}
        </div>

        <QuotaAdd modal-id="QuotaAddModal"
            :show="showQuotaAdd" 
            @close="showQuotaAdd = false" 
            @error="(data) => handleError(data)"
            @success="(data) => handleSuccess(data)" />

        <!-- Error Modal -->
        <ErrorBox modal-id="QuotaErrorBox"
            :show="error" 
            :message="error" 
            @close="error=false" />

        <!-- Confirm Trash Box -->
        <ConfirmBox modal-id="QuotaConfirmTrash"
            :show="showConfirmTrash"
            message="Êtes vous certain de vouloir supprimer ce/ces profil(s) ? Si des membres utilisent ce/ces profil(s), ils seront assignés au profil par défaut."
            typeof="question"
            @close="showConfirmTrash = false"
            @confirm="deleteSelectedQuota()" />
            
        <!-- Progress Bar -->
        <ProgressBar modal-id="QuotaProgressBar" 
            :show="showProgress"
            :percent="progressPercent"
            :message="progressMessage"
            @close="progressFinished()"
            />
        
    </div>
</template>

<script setup>

    import {onMounted, ref, defineEmits, defineProps} from "vue";
    import axios from "axios";
    import QuotaAdd from "./components/QuotaAdd.vue";
    import ErrorBox from "./components/generics/ErrorBox.vue";
    import ConfirmBox from "./components/generics/ConfirmBox.vue";
    import ProgressBar from "./components/generics/ProgressBar.vue";

    let props = defineProps(["userInfo"]);

    let loaded = ref(false);
    let error = ref(false);
    let quotas = ref([]);
    let selectedQuotas = ref([]);

    let showQuotaAdd = ref(false);
    let showConfirmTrash = ref(false);
    let showProgress = ref(false);
    let progressPercent = ref(false);
    let progressMessage = ref("");

    onMounted( () => {
        loaded.value = false;
        load();
    })

    function load(){
        axios.get("/api/quotas")
            .then( answer => {
                quotas.value = answer.data;
                loaded.value = true;
            })
            .catch( answer => {
                alert( (answer.response.data.message) ? answer.response.data.message : answer );
            });
    }

    function handleError(data){
        error.value = data;
    }
    
    function handleSuccess(data){
        load();
    }

    function deleteSelectedQuota(){
        showConfirmTrash.value = false;
        showProgress.value = true;
        try{
            let i = 0; //-> to Send axios request
            let f = 0; //-> to follow received answers
            while(i < selectedQuotas.value.length){
                let quotaId = selectedQuotas.value[i];
                axios.delete(`/api/quotas/${quotaId}`)
                    .then( answer => {
                        progressMessage.value =`Supression des profils en cours (${i}/${selectedQuotas.value.length})...`;
                        progressPercent.value = ( (f+1) / selectedQuotas.value.length ) * 100;
                        if(f===selectedQuotas.value.length) progressMessage.value =`Action terminée !`;
                        f++;
                    })
                    .catch( e => {
                        progressMessage.value = `Erreur !`;
                        progressPercent.value = 100;
                        error.value = (e.response.data.message) ? e.response.data.message : e;
                        f=selectedQuotas.value.length;
                        i=selectedQuotas.value.length;
                    })
                i++;
            }
        }
        catch(e){
            progressPercent.value = 100;
            error.value = (e.response.data.message) ? e.response.data.message : e;
        }
    }

    function progressFinished(){
        showProgress.value = false;
        progressMessage.value = "";
        progressPercent.value = 0;
        load();
    }

</script>